import React from "react"
import { Helmet } from "react-helmet"

// css
import styled from "styled-components"
import _logo from "../assets/logo.png"
import _preview1 from "../assets/preview1.jpg"
import _preview2 from "../assets/preview2.jpg"
import _appstore from "../assets/appstore.svg"
import _playstore from "../assets/google_playstore.png"
import _ainbr_og from "../assets/ainbr_og.png"
import { createGlobalStyle } from 'styled-components';
import { OutboundLink } from "gatsby-plugin-google-gtag"

const GlobalStyle = createGlobalStyle`
  // import opensans 400,700
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
  /* @import url('https://fonts.googleapis.com/css?family=Kodchasan:400,700'); */
  /* <link href='https://fonts.googleapis.com/css?family=Kodchasan:400,700' rel='stylesheet' type='text/css'> */
  body {
    padding: 0;
    margin: 0;
    font-family: Open Sans, sans-serif;
  }
  a {
    text-decoration: none;
  }
  ul {
    margin: 0 auto;
    list-style-type: none;
  }
`

const NavigationBar = styled.div`
  height: 60px;
  background: white;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavigationContainer = styled.div`
  width: 100%;
  max-width: 1500px;
`

const Section = styled.div`
  @media only screen and (min-width: 992px) {
    height: calc(100vh - 60px)
  };
  
  // height: calc(100vh - 60px);
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
`

const Section1 = styled(Container)`
  padding: 60px;
  /* height: 690px; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;

  @media only screen and (max-width: 992px) {
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
  };
`

const Logo = styled.div`
  background: url(${_logo}) no-repeat;
  background-size: cover;
  width: 80px;
  height: 40px;
  /* if it's mobil, make it center */
  @media only screen and (max-width: 992px) {
    margin: 0 auto;
  }
`

const Intro = styled.div`
  width: 100%;
  max-width: 550px;

  @media only screen and (max-width: 992px) {
    text-align: center;
    margin: 0px 0px 60px;
  }
`

const Headline = styled.div`
  color: #333333;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 30px;
`

const Description = styled.div`
  color: #333333;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.4;
  margin-bottom: 39px;
`

const Preview = styled.div`
  width: 100%;
  max-width: 466px;
  max-height: 530px;
  display: flex;

  @media only screen and (max-width: 550px) {
    transform: scale(0.8);
    justify-content: center;
    align-items: center;
  }
`

const PreviewLayout = styled.div`
  width: 236px;
  height: 510px;
  overflow: visible;
  background-color: #ffffff;
  border-radius: 38px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index:1;
`

const PreviewImage1 = styled.div`
  background: url(${_preview1}) no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  width: 236px;
  height: 511px;
  overflow: visible;
  border-radius: 24px;
`

const PreviewImage2 = styled.div`
  width: 210px;
  height: 454px;
  overflow: visible;
  border-radius: 24px;
  background-image: url(${_preview2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  image-rendering: auto;
  transform: translateX(-40px);
  margin-top: 38px;
  /* z-index:1; */
  @media only screen and (max-width: 550px) {
    display: none;
  }
`

const seo = {
  url: `https://www.ainbr.com/`,
  image: {},
  description: `Scan bourbon, whiskey, rye, scotch, tequila, vodka, gin bottle labels with your phone's camera and get relevant information in seconds.`,
  title: `Ainbr - Bourbon Whiskey App`,
}

const AppStoreButtonOnClick = (e) => {
  const urlParams = new URLSearchParams(window.location.search);
  let source = "ainbr.com";
  if ( urlParams.get('from') ) {
    source = source + "_from_" + urlParams.get('from');
  }
  else if ( urlParams.get('l') ) {
    source = source + "_from_l";
  }
  
  window.open(`https://apps.apple.com/app/apple-store/id1516194643?pt=121000177&ct=${source}&mt=8`, "_blank")
  e.preventDefault()
}

const detectAndOpenAppStoreOrPlayStore = () => {
  if(typeof window !== 'undefined') {
    // Detect if device is on iOS or Android and open App Store or Play Store respectively.
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.open(`https://play.google.com/store/apps/details?id=com.ainbr.android`, "_blank")
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open(`https://apps.apple.com/app/apple-store/id1516194643?pt=121000177&ct=ainbr.com&mt=8`, "_blank")
    }
  }
}

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Scan bourbon, whiskey, rye, scotch, tequila, vodka, gin bottle labels with your phone's camera and get relevant information in seconds."/>
        <link href='https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,900&display=swap' rel='stylesheet' type='text/css'/>
        <title>Ainbr - Bourbon Whiskey App</title>

        <meta property="og:title" content={seo.title}/>
        <meta property="og:description" content={seo.description}/>
        <meta property="og:type" content="website"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:url" content={seo.url}/>
        <meta property="og:image" content={_ainbr_og}/>
        <meta property="og:site_name" content="Ainbr"/>
      </Helmet>
      <GlobalStyle/>
      <NavigationBar>
        <NavigationContainer>
          <Logo></Logo>
        </NavigationContainer>
      </NavigationBar>
      <Section>
        <Section1>
          <Intro>
            <Headline>
              Plan Your Perfect Pour
            </Headline>
            <Description>
              Find the right drink at the right price. Supports bourbon, whiskey, rye, scotch, tequila, vodka, gin and more.
            </Description>
            <OutboundLink style={{marginRight: '5px'}} onClick={AppStoreButtonOnClick} href="https://apps.apple.com/us/app/id1516194643">
              <img src={_appstore} alt="App Store"/>
            </OutboundLink>
            <OutboundLink style={{marginRight: '5px'}} href="https://play.google.com/store/apps/details?id=com.ainbr.android">
              <img src={_playstore} alt="App Store" height="50"/>
            </OutboundLink>
          </Intro>
          <Preview>
            <PreviewLayout>
              <PreviewImage1></PreviewImage1>
            </PreviewLayout>
            <PreviewImage2></PreviewImage2>
          </Preview>
        </Section1>
      </Section>
      <script>
        {detectAndOpenAppStoreOrPlayStore()}
      </script>
    </>
  )
};

export default Home;
